<template>
  <b-modal
    id="help-modal"
    :visible="shallShowHelpModal"
    title="Help"
    hide-footer
    body-class="p-2"
    size="lg"
    
    @change="(val) => $emit('update:shall-show-help-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h4 class="mx-auto modal-title text-center">
        Help
      </h4>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="closeModal"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <div v-html="content"></div>
    
  </b-modal>
</template>

<script>

export default {
  model: {
    prop: 'shallShowHelpModal',
    event: 'update:shall-show-help-modal',
  },
  props: {
    content: {
      type: String,
      required: true
    },
    shallShowHelpModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
   const closeModal = () => {
      emit('update:shall-show-help-modal', false)
    }

    return {
      closeModal,
    }
  },
}
</script>

